import React from 'react';

import { Box, useTheme, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { NewsConfig } from '@/configs/NewsConfig';
import useBreakpoints from '@/helpers/useBreakpoints';
import { NewsTypes } from '@/types/news';

interface SubItemsProps {
  listNews: NewsTypes[];
}

const SubItems: React.FC<SubItemsProps> = ({ listNews }) => {
  const theme = useTheme();
  const locale = localStorage.getItem('locale') || 'vi';
  const { isMobile, isTablet } = useBreakpoints();
  const { checkValidUrl } = NewsConfig();

  const listItems = !isMobile && !isTablet ? listNews.slice(4, 6) : listNews.slice(1, 3);

  return (
    <>
      {listItems.map((item: NewsTypes) => (
        <React.Fragment key={item.id}>
          <Box
            key={item.id}
            sx={{
              display: { xs: '', sm: 'flex', md: 'flex' },
              maxWidth: '800px',
              mb: { xs: 0, md: 3 },
              padding: { xs: '25px 0px 25px 0px', sm: '15px 0px 15px 0px', md: '0px' },
            }}
          >
            <Link to={`/${item.path}`} style={{ width: '40%' }}>
              <Box
                sx={{
                  border: `1px solid ${theme.palette.divider}`,
                  cursor: 'pointer',
                  borderRadius: 2,
                  overflow: 'hidden',
                  alignSelf: 'center',
                  ':hover': {
                    '.img': {
                      transform: 'scale(1.1)',
                    },
                  },
                }}
              >
                <img
                  style={{ width: '100%', height: '170px', transition: '0.6s' }}
                  className="img"
                  alt=""
                  src={checkValidUrl(item.img_url, item.image_url_minio)}
                />
              </Box>
            </Link>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: { xs: '100%', md: '65%' },
                padding: { xs: 0, sm: '0px 0px 0px 20px' },
                mt: { xs: 2, sm: 0 },
              }}
            >
              <Link to={`/${item.path}`} style={{ textDecoration: 'none' }}>
                <Box
                  sx={{
                    color: 'text.primary',
                    cursor: 'pointer',
                    '&:hover': {
                      color: '#02BB9F',
                    },
                    fontSize: '1.8rem',
                    transition: 'color 0.3s ease',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    WebkitLineClamp: 3,
                    textOverflow: 'ellipsis',
                    fontWeight: 600,
                    lineHeight: '1.44em',
                  }}
                >
                  {locale === 'vi' ? item.title_vi : item.title_en}
                </Box>
              </Link>
              <Typography
                sx={{
                  fontSize: '1.4rem',
                  color: 'text.secondary',
                  mt: 1,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  WebkitLineClamp: 3,
                  textOverflow: 'ellipsis',
                  fontWeight: 500,
                }}
              >
                {locale === 'vi' ? item.excerpt_vi : item.excerpt_en}
              </Typography>
            </Box>
          </Box>
        </React.Fragment>
      ))}
    </>
  );
};
export default SubItems;
